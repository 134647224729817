<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3><i class="el-icon-user"></i>Distribuidores</h3>
      </div>
      <div class="botones">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          v-if="!$usuarioEs.supervisor()"
          @click="$refs.modalNuevo.abrir()"
          >Nuevo Distribuidor</el-button
        >
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :min-width="120" label="Nombre">
            <template slot="header">
              <maca-datatable-filtrar label="Nombre" v-model="filtroNombre"></maca-datatable-filtrar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.nombre"></span>
            </template>
          </el-table-column>
          <el-table-column label="Email">
            <template slot-scope="props">
              <span v-text="props.row.email"></span>
            </template>
          </el-table-column>
          <el-table-column label="Zona">
            <template slot="header">
              <maca-datatable-filtrar-select label="Zona" v-model="filtroZona" :urlSelect="urlZona"></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <div v-if="props.row.detalleZona != null" v-for="(item, index) in props.row.detalleZona" :key="index">
                <el-tag
                    style="margin-top: 3px; margin-bottom: 3px"
                    type="info"
                    v-text="item.zona"
                ></el-tag>
              </div>
              <div v-else>0 zonas</div>
            </template>
          </el-table-column>
          <el-table-column label="Ver" width="70">
            <template slot-scope="props">
              <el-button
                type="success"
                size="small"
                @click="$refs.modalVer.abrir(props.row.id)"
                plain
                round
              >
                <i class="el-icon-view"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column v-if="!$usuarioEs.supervisor()" label="Editar" width="70">
            <template slot-scope="props">
              <el-button
                  type="warning"
                  size="small"
                  @click="$refs.modalModificar.abrir(props.row.id)"
                  plain
                  round
              >
                <i class="el-icon-edit"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column v-if="!$usuarioEs.supervisor()" label="Borrar" width="70">
            <template slot-scope="props">
              <el-button
                type="danger"
                size="small"
                @click="eliminar(props.row.id)"
                plain
                round
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-ver
      ref="modalVer"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-ver>
    <modal-nuevo
        ref="modalNuevo"
        @actualizar-tabla="actualizarTabla = true"
    ></modal-nuevo>
    <modal-modificar
      ref="modalModificar"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-modificar>
  </div>
</template>

<script>
import ModalVer from "./modales/ver";
import ModalNuevo from "./modales/nuevo";
import ModalModificar from "./modales/modificar";

export default {
  name: "usuario",
  components: {
    ModalVer,
    ModalNuevo,
    ModalModificar,
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/distribuidorMayorista/obtenerTodos",
      paramsTabla: [],
      actualizarTabla: true,
      bloquearTabla: true,

      urlZona: this.$api.URL + "/zona/obtenerTodosSelect",

      filtroNombre: null,
      filtroZona: null,
    };
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el distribuidor."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { distribuidorMayoristaID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/distribuidorMayorista/eliminar",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Distribuidor borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    filtroNombre() {
      if (this.filtroNombre != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "nombre",
            this.filtroNombre
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "nombre");
      }

      this.actualizarTabla = true;
    },
    filtroZona() {
      if (this.filtroZona != null) {
        this.$common.agregarUrlParam(
            this.paramsTabla,
            "zonaID",
            JSON.stringify(this.filtroZona.id)
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "zonaID");
      }

      this.actualizarTabla = true;
    },
  },
};
</script>
