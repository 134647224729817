<template>
  <div>
    <maca-modal
      titulo="Modificar Distribuidor"
      :impedirClose="impedirClose"
      ref="modal"
    >
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px">
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="E-mail" prop="email">
          <el-input type="email" v-model="form.email"></el-input>
        </el-form-item>
        <el-form-item label="Zona" prop="zona">
          <maca-select-box
            icon="el-icon-s-flag"
            v-model="form.zona"
            :url="urlZona"
          ></maca-select-box>
        </el-form-item>

        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "nuevo-usuario",
  data() {
    return {
      form: {
        nombre: "",
        email: "",
        zona: "",
      },
      impedirClose: false,
      persona: null,
      id2: null,

      urlRol: this.$api.URL + "/user/obtenerTodosSelectRoles",
      urlZona: this.$api.URL + "/zona/obtenerTodosSelect",

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
        zona: [
          {
            required: true,
            message: "Por favor introduzca la zona.",
            trigger: "change",
          },
        ],
        email: [
          {
            required: true,
            message: "Por favor introduzca el email.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }
      this.id2 = null;

      this.id = id;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$api.get(
        this.$api.URL +
          "/distribuidorMayorista/obtenerDatos?distribuidorMayoristaID=" +
          this.id,
        this.$usuarioToken()
      );

      this.cargando = false;

      if (respuestaApi != null) {
        //console.log("respuesta: ", respuestaApi);
        this.form.nombre = respuestaApi.nombre;
        this.form.email = respuestaApi.email;
        if (respuestaApi.detalleZona != null) {
          this.form.zona = {
            id: respuestaApi.detalleZona[0].zonaID,
            nombre: respuestaApi.detalleZona[0].zona,
          };
          this.id2 = respuestaApi.detalleZona[0].id;
        }
        //console.log("detalleZona: ", this.form.detalleZona);
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk, _) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let arreglo = [];
      const formData = new FormData();

      formData.append("distribuidorMayoristaID", this.id);
      formData.append("nombre", this.form.nombre);
      formData.append("email", this.form.email);
      arreglo.push({
        distribuidorMayoristaZonaID: this.id2,
        zonaID: this.form.zona.id,
        accion: "M",
      });
      formData.append("detalleZona", JSON.stringify(arreglo));

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/distribuidorMayorista/actualizar",
        formData,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Distribuidor creado con éxito!",
          type: "success",
        });

        return true;
      } else if (respuestaApi.estado === 0) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0
        });
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }

      return false;
    },
  },
  watch: {
    persona() {
      if (this.persona == null) {
        this.form.personaID = null;
        return;
      }

      this.form.nombre = this.persona.nombre;
      this.form.apellido = this.persona.apellido;
      this.form.dni = this.persona.dni;
      this.form.email = this.persona.email;
      this.form.calle = this.persona.calle;
      this.form.nro = this.persona.nro;
      this.form.piso = this.persona.piso;
      this.form.dpto = this.persona.dpto;
    },
  },
};
</script>
