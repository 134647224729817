var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "contenedor-titulo" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "botones" },
          [
            !_vm.$usuarioEs.supervisor()
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "primary", icon: "el-icon-plus", plain: "" },
                    on: {
                      click: function($event) {
                        return _vm.$refs.modalNuevo.abrir()
                      }
                    }
                  },
                  [_vm._v("Nuevo Distribuidor")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("el-card", { attrs: { "body-style": { padding: "20px" } } }, [
        _c(
          "div",
          [
            _c(
              "maca-datatable",
              {
                attrs: {
                  url: _vm.urlTabla,
                  params: _vm.paramsTabla,
                  actualizar: _vm.actualizarTabla,
                  bloquear: _vm.bloquearTabla
                },
                on: {
                  "update:actualizar": function($event) {
                    _vm.actualizarTabla = $event
                  },
                  "update:bloquear": function($event) {
                    _vm.bloquearTabla = $event
                  }
                }
              },
              [
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 120, label: "Nombre" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.nombre)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar", {
                          attrs: { label: "Nombre" },
                          model: {
                            value: _vm.filtroNombre,
                            callback: function($$v) {
                              _vm.filtroNombre = $$v
                            },
                            expression: "filtroNombre"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { label: "Email" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: { textContent: _vm._s(props.row.email) }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: { label: "Zona" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return _vm._l(props.row.detalleZona, function(
                            item,
                            index
                          ) {
                            return props.row.detalleZona != null
                              ? _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c("el-tag", {
                                      staticStyle: {
                                        "margin-top": "3px",
                                        "margin-bottom": "3px"
                                      },
                                      attrs: { type: "info" },
                                      domProps: {
                                        textContent: _vm._s(item.zona)
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c("div", [_vm._v("0 zonas")])
                          })
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-select", {
                          attrs: { label: "Zona", urlSelect: _vm.urlZona },
                          model: {
                            value: _vm.filtroZona,
                            callback: function($$v) {
                              _vm.filtroZona = $$v
                            },
                            expression: "filtroZona"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { label: "Ver", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                size: "small",
                                plain: "",
                                round: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.modalVer.abrir(props.row.id)
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-view" })]
                          )
                        ]
                      }
                    }
                  ])
                }),
                !_vm.$usuarioEs.supervisor()
                  ? _c("el-table-column", {
                      attrs: { label: "Editar", width: "70" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "warning",
                                      size: "small",
                                      plain: "",
                                      round: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$refs.modalModificar.abrir(
                                          props.row.id
                                        )
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-edit" })]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3860868514
                      )
                    })
                  : _vm._e(),
                !_vm.$usuarioEs.supervisor()
                  ? _c("el-table-column", {
                      attrs: { label: "Borrar", width: "70" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      size: "small",
                                      plain: "",
                                      round: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.eliminar(props.row.id)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-delete" })]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2934779640
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("modal-ver", {
        ref: "modalVer",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-nuevo", {
        ref: "modalNuevo",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      }),
      _c("modal-modificar", {
        ref: "modalModificar",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titulo" }, [
      _c("h3", [
        _c("i", { staticClass: "el-icon-user" }),
        _vm._v("Distribuidores")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }